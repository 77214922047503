@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oregano&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oregano&family=Poppins&family=Tiro+Devanagari+Sanskrit&display=swap');
@import url('https://fonts.cdnfonts.com/css/magnitude');
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
                
@tailwind base;
@tailwind components;
@tailwind utilities;

input::placeholder {
  font-size: 16px;
}


.shadow-custom {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar {
  width: 10px; 
}


::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}


::-webkit-scrollbar-thumb {
  background: linear-gradient(80deg, #000B15 40%, #0B2447);
  border-radius: 5px; 
}


::-webkit-scrollbar-thumb:hover {
  background-color: #000B15; 
}


.main-button {
  background-color: #000B15;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.main-button:hover {
  background-color: #fff;
  color: #000B15;
  box-shadow: 0px 0px 10px #333;
}
.second-button {
  background-color: #E14D2A;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.second-button:hover {
  background-color: #fff;
  color: #E14D2A;
  box-shadow: 0px 0px 10px #333;
}
.linear{
  background: linear-gradient(80deg, #000B15 40%, #0B2447);

}
.navLinks{
  /* background:linear-gradient(80deg, #7FB5FF , #d033d5) ; */
  background: linear-gradient(80deg, #001c35 40%, #18355d);
  color:white;
 
}
.tittle{
  font-size: xx-large;
  font-weight: 600;
  font-family: oregano;
}

.animate-dropdown {
  animation-name: dropdown;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
}

@keyframes dropdown {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100vh;
  }
}


.slick-dots {
  bottom: -30px; /* Adjust dot position if needed */
}










